import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "input", "table", "row" ]
    static classes = [ "hidden" ]

    search() {
        const searchTerm = this.inputTarget.value.toLowerCase()

        this.rowTargets.forEach(row => {
            const cells = row.getElementsByTagName('td')
            const isMatch = Array.from(cells).some(cell =>
                cell.textContent.toLowerCase().includes(searchTerm)
            )

            row.classList.toggle(this.hiddenClass, !isMatch)
        })
    }
}