// import { Controller } from '@hotwired/stimulus';
//
// export default class extends Controller {
//     static targets = ['inventoryTable', 'contactTable'];
//     static values = {
//         estateId: Number
//     }
//
//     connect() {
//         this.initializeDragAndDrop();
//     }
//
//     initializeDragAndDrop() {
//         this.inventoryTableTarget.querySelectorAll('tbody tr').forEach(row => {
//             row.addEventListener('dragstart', this.onDragStart.bind(this));
//         });
//
//         this.contactTableTarget.querySelectorAll('tbody tr').forEach(row => {
//             row.addEventListener('dragover', this.onDragOver.bind(this));
//             row.addEventListener('drop', this.onDrop.bind(this));
//         });
//     }
//
//     onDragStart(event) {
//         const inventoryId = event.target.closest('tr').dataset.inventoryId;
//         event.dataTransfer.setData('text/plain', inventoryId);
//         event.target.closest('tr').classList.add('dragging');
//     }
//
//     onDragOver(event) {
//         event.preventDefault();
//     }
//
//     onDrop(event) {
//         event.preventDefault();
//         const inventoryId = event.dataTransfer.getData('text/plain');
//         const contactRow = event.target.closest('tr');
//         const contactId = contactRow.dataset.contactId;
//
//         this.dispatch('assign-inventory', {
//             detail: {
//                 inventoryId: parseInt(inventoryId),
//                 contactId: parseInt(contactId),
//                 estateId: this.estateIdValue
//             }
//         });
//     }
// }

import { Controller } from '@hotwired/stimulus';
import { getComponent } from "@symfony/ux-live-component";

export default class extends Controller {
    static targets = ['inventoryTable', 'contactTable'];

    connect() {
        this.initializeDragAndDrop();
    }

    async initializeDragAndDrop() {
        let inventoryTable = document.getElementById('inventory-table');
        let contactTable = document.getElementById('contacts-table');
        this.liveComponent = await getComponent(this.element);
        // Add draggable attribute to inventory rows
        inventoryTable.querySelectorAll('tbody tr').forEach(row => {
            row.setAttribute('draggable', 'true');
            row.addEventListener('dragstart', this.onDragStart.bind(this));
            row.addEventListener('dragend', this.onDragEnd.bind(this));
        });

        // Add drop targets to contact rows and tables
        contactTable.addEventListener('dragover', this.onDragOver.bind(this));
        contactTable.addEventListener('drop', this.onDrop.bind(this));
    }

    onDragStart(event) {
        const row = event.target.closest('tr');
        const inventoryId = row.dataset.inventoryId;

        // Ensure the dragged data is set
        event.dataTransfer.setData('text/plain', inventoryId);

        // Visual feedback
        row.classList.add('bg-light');
    }

    onDragEnd(event) {
        const row = event.target.closest('tr');
        row.classList.remove('bg-light');
    }

    onDragOver(event) {
        // Necessary to allow dropping
        event.preventDefault();
    }

    onDrop(event) {
        event.preventDefault();

        // Find the contact row or closest parent row
        const contactRow = event.target.closest('tr[data-contact-id]');

        if (!contactRow) return;

        const inventoryId = event.dataTransfer.getData('text/plain');
        const contactId = contactRow.dataset.contactId;

        //Use if need to call liveListener of component

        // const listener = this.liveComponent.listeners.get('assign-inventory');
        //
        // if (!listener) return;


        // this.liveComponent.emit('assign-inventory', {
        //     inventoryId: inventoryId,
        //     contactId: contactId,
        // });

        const id = this.element.dataset.dragDropEstateIdValue;
        const data = {
            inventoryId: inventoryId,
            contactId: contactId,
            userInventoryId: null,
            id: id
        };

        // Send data via fetch
        fetch(window.location.origin + `/admin/estates/${id}/inventory/share/add`, {
            method: 'POST', // Use GET if necessary
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                umbrella.jsResponseHandler.success(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });


    }
}