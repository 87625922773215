import { Controller } from '@hotwired/stimulus';
import { Offcanvas } from 'bootstrap';

export default class extends Controller {
    static targets = ["offcanvas"];
    offcanvas = null;

    connect() {
        this.offcanvas = Offcanvas.getOrCreateInstance(this.offcanvasTarget);

        const Tchat = this.data.get('tchat');
        if (Tchat !== null)
        {
            localStorage.setItem('tchat', Tchat);
        }

        // Restore the offcanvas state when the page loads
        const offcanvasState = localStorage.getItem('offcanvasState');

        document.addEventListener("shown.bs.offcanvas", () => {
            // Set the localStorage state to 'open' when the offcanvas is opened
            localStorage.setItem('offcanvasState', 'open');
        });

        // Listen for when the offcanvas is hidden and set the state to 'closed'
        document.addEventListener("hidden.bs.offcanvas", () => {
            this.hide();
        });
    }

    show() {
        console.log('Showing offcanvas');
        this.offcanvas.show();
        // Save the state as 'open' in localStorage
        localStorage.setItem('offcanvasState', 'open');
    }

    hide() {
        console.log('Hiding offcanvas');
        this.offcanvas.hide();
        // Save the state as 'closed' in localStorage
        localStorage.setItem('offcanvasState', 'closed');
    }
}
