import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        maxWindows: Number
    };

    static targets = ['messageLink'];

    connect() {
        this.maxWindowsValue = 2;
        this.isRequestPending = false;
        this.initializeChatContainer();
    }

    async openChat(event) {
        event.preventDefault();

        // If there's already a request in progress, ignore the new request
        if (this.isRequestPending) {
            console.log('Request already in progress, ignoring...');
            return;
        }

        // Disable all message links
        this.disableMessageLinks();
        this.adjustMinimizedChatsPositions();

        const channelId = event.currentTarget.dataset.channelId;
        const url = event.currentTarget.dataset.route;

        // Retrieve the current openChats array from local storage or initialize it
        let openChats = JSON.parse(localStorage.getItem('openChats')) || [];

        // Check if channelId is already in openChats and remove it if it exists
        const index = openChats.indexOf(channelId);
        if (index !== -1) {
            openChats.splice(index, 1);
        }

        // Add the new channelId to the end of openChats
        openChats.push(channelId);

        // If openChats exceeds the max limit, remove the oldest entry and its minimized bar
        if (openChats.length > this.maxWindowsValue) {
            const oldestChannelId = openChats.shift(); // Remove the oldest chat
            // Remove the minimized bar for the oldest chat if it exists
            const oldestMinimizedBar = document.getElementById(`minimizedChatBar_${oldestChannelId}`);
            if (oldestMinimizedBar) {
                oldestMinimizedBar.remove();
            }
        }

        // Update local storage with the new openChats array
        localStorage.setItem('openChats', JSON.stringify(openChats));

        // Check if chat is already open using channel ID
        const existingChat = document.querySelector(`[data-chat-manager-target="chatWindow"][data-channel-id="${channelId}"]`);
        if (existingChat) {
            existingChat.remove();
            // Also remove any existing minimized bar for this chat
            const existingMinimizedBar = document.getElementById(`minimizedChatBar_${channelId}`);
            if (existingMinimizedBar) {
                existingMinimizedBar.remove();
            }
        }

        // Check and manage maximum windows
        const chatWindows = document.querySelectorAll('[data-chat-manager-target="chatWindow"]');
        if (chatWindows.length >= this.maxWindowsValue) {
            const oldestWindow = chatWindows[0];
            const oldestChannelId = oldestWindow.dataset.channelId;
            oldestWindow.remove();
            // Remove the minimized bar for the oldest window if it exists
            const oldestMinimizedBar = document.getElementById(`minimizedChatBar_${oldestChannelId}`);
            if (oldestMinimizedBar) {
                oldestMinimizedBar.remove();
            }
        }

        try {
            this.isRequestPending = true;

            const response = await fetch(window.location.origin + url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/html',
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            umbrella.jsResponseHandler.success(data);
            console.log('Success: message window content loaded successfully.');

        } catch (error) {
            console.error('Error loading message window content:', error);
        } finally {
            // Re-enable message links and reset the pending flag
            this.enableMessageLinks();
            this.isRequestPending = false;
        }
    }

    disableMessageLinks() {
        // Disable all message links with id="message-link"
        const messageLinks = document.querySelectorAll('a#message-link');
        messageLinks.forEach(link => {
            link.classList.add('disabled');
            link.setAttribute('disabled', 'disabled');
            link.style.pointerEvents = 'none';
            link.style.opacity = '0.6';

            // If the link has a spinner, show it
            if (link.dataset.spinner === 'true') {
                // Add a loading spinner if needed
                const spinner = document.createElement('span');
                spinner.className = 'spinner-border spinner-border-sm ms-2';
                spinner.setAttribute('role', 'status');
                link.appendChild(spinner);
            }
        });
    }

    enableMessageLinks() {
        // Re-enable all message links with id="message-link"
        const messageLinks = document.querySelectorAll('a#message-link');
        messageLinks.forEach(link => {
            link.classList.remove('disabled');
            link.removeAttribute('disabled');
            link.style.pointerEvents = 'auto';
            link.style.opacity = '1';

            // Remove any spinners that were added
            const spinner = link.querySelector('.spinner-border');
            if (spinner) {
                spinner.remove();
            }
        });
    }

    isChatOpen(channelId) {
        const existingChat = document.querySelector(`[data-chat-manager-target="chatWindow"][data-channel-id="${channelId}"]`);
        return !!existingChat;
    }

    focusChat(channelId) {
        const chatWindow = document.querySelector(`[data-chat-manager-target="chatWindow"][data-channel-id="${channelId}"]`);
        if (chatWindow) {
            chatWindow.classList.add('border-primary');
            setTimeout(() => {
                chatWindow.classList.remove('border-primary');
            }, 1000);
        }
    }

    closeChat(event) {
        const chatWindow = event.target.closest('[data-chat-manager-target="chatWindow"]');
        if (chatWindow) {
            const channelId = chatWindow.dataset.channelId;

            // Remove the channel ID from localStorage
            let openChats = JSON.parse(localStorage.getItem('openChats')) || [];
            const index = openChats.indexOf(channelId);
            if (index !== -1) {
                openChats.splice(index, 1);
                localStorage.setItem('openChats', JSON.stringify(openChats));
            }

            // Remove the chat window from DOM
            chatWindow.remove();

            // Remove the minimized bar if it exists
            const minimizedBar = document.getElementById(`minimizedChatBar_${channelId}`);
            if (minimizedBar) {
                minimizedBar.remove();
            }
        }
    }

    minimizeTchat(event) {
        const channelId = event.currentTarget.dataset.channelId;
        const chatWindow = document.getElementById(`chatWindow-${channelId}`);
        const chatBody = document.getElementById(`chatContent_${channelId}`);
        const container = document.getElementById('minimized-chats-container');
        const messageList = document.getElementById('messages_list');
        const icon = event.currentTarget.querySelector('i');

        if (chatBody.classList.contains('d-none')) {
            // Maximize
            chatBody.classList.remove('d-none');
            chatWindow.classList.remove('fixed-bottom');
            chatWindow.style.maxHeight = '700px';

            // Remove from minimized container if it's there
            if (container.contains(chatWindow)) {
                messageList.appendChild(chatWindow);
            }
            icon.classList.replace('ki-arrow-up', 'ki-minus');

            event.currentTarget.setAttribute('title', 'Minimize');
            event.currentTarget.setAttribute('aria-expanded', 'true');
        } else {
            // Minimize
            chatBody.classList.add('d-none');
            chatWindow.classList.add('fixed-bottom');
            chatWindow.style.maxHeight = '100px';
            chatWindow.style.position = 'relative';
            chatWindow.style.right = 'auto';
            chatWindow.style.bottom = 'auto';
            icon.classList.replace('ki-minus', 'ki-arrow-up');

            // Move to minimized container
            container.appendChild(chatWindow);

            event.currentTarget.setAttribute('title', 'Expand');
            event.currentTarget.setAttribute('aria-expanded', 'false');
        }

        this.adjustMinimizedChatsPositions();
    }

    adjustMinimizedChatsPositions() {
        const container = document.getElementById('minimized-chats-container');
        const minimizedChats = container.querySelectorAll('[data-chat-manager-target="chatWindow"]');

        minimizedChats.forEach((chat, index) => {
            chat.style.width = '320px';  // Ensure consistent width
            chat.style.margin = '0';     // Reset margins
        });
    }

    initializeChatContainer() {
        // Create a container for minimized chats if it doesn't exist
        if (!document.getElementById('minimized-chats-container')) {
            const container = document.createElement('div');
            container.id = 'minimized-chats-container';
            container.style.cssText = `
                position: fixed;
                bottom: 0;
                right: 0;
                display: flex;
                flex-direction: row-reverse;
                gap: 1rem;
                padding: 0 1rem;
                z-index: 1050;
            `;
            document.body.appendChild(container);
        }
    }
}